import { getTokenFromLocalStorage } from '@redux/localStorage/checkout';
import { renderDealerLoginUrl, renderSendSalespersonId } from '@utils/DealerUrls';
import Axios from 'axios'

const login = async (apiUrl:string, email:string, userId: string) =>{
    const url = renderDealerLoginUrl(apiUrl, email, userId);
    const config = {
        headers: { 'Access-Control-Allow-Origin': "*"}
    };
    const result = await Axios.get(url, config);
    return result.data;
}

const sendSalespersonId =async (apiUrl:string, email:string) => {
    const url = renderSendSalespersonId(apiUrl, email);
    const config = {
        headers: { 'Access-Control-Allow-Origin': "*"}
    };
    const result = await Axios.get(url, config);
    return result.data;
}

const verifySalesPerson = async (apiUrl:string, email:string) => {
    const url = `${apiUrl}/dealer/verifydealeremail?email=${email}`;
    const config = {
        headers: { 'Access-Control-Allow-Origin': "*"}
    };
    const result = await Axios.get(url, config);
    return result.data;
}

const getDealer = async (apiUrl:string, email:string) => {
    const url = `${apiUrl}/dealer/getDealer?email=${email}`;
    const config = {
        headers: { 'Access-Control-Allow-Origin': "*"}
    };
    const result = await Axios.get(url, config);
    return result.data;
}

const updateDealerRegistration = async (apiUrl:string, email:string) => {
    const url = `${apiUrl}/dealer/updatedealerregistration?email=${email}`;
    var token = getTokenFromLocalStorage()
    const config = {
        headers: { 'Access-Control-Allow-Origin': "*",
                    'Authorization': `Bearer ${token}`
                 }};
    const result = await Axios.get(url, config);
    return result.data;
}

const getDealerOrder = async (apiUrl:string, id:string) => {
    const url = `${apiUrl}/dealer/getdealerorder?id=${id}`;
    var token = getTokenFromLocalStorage()
    const config = {
        headers: { 'Access-Control-Allow-Origin': "*",
                    'Authorization': `Bearer ${token}`
                 }};
    const result = await Axios.get(url, config);
    return result.data;
}

export const DealerService = {
    login : login,
    sendSalespersonId: sendSalespersonId,
    verifySalesPerson: verifySalesPerson,
    getDealer: getDealer,
    updateDealerRegistration: updateDealerRegistration,
    getDealerOrder: getDealerOrder
}