import React, { useEffect } from "react"
import { Layout } from "@components/Layout"
import { SEO } from "@components/SEO"
import { Auth } from "aws-amplify"
import { AuthPageWrapper, SignUpWrapper } from "@auth/index.styles"
import { Form, Grid, Icon, Input } from "semantic-ui-react"
import { Caption, H6 } from "@styles/Global.styles"
import { LoadingIcon } from "@components/OrderConfirmation/OrderConfirmation.styles"
import theme from "@styles/theme"
import { KpButton } from "@elements/index"
import { ButtonTypes } from "@utils/Constant"
import { AuthFormLabel } from "@styles/Global.forms"
import { DealerService } from "@services/DealerService"
import { graphql, navigate } from "gatsby"

const queryString = require("query-string")

const ConfirmSignupPage = (props: any) => {
  const query = queryString.parse(props.location.search)
  const username = query.username
  const code = query.code
  const [verified, setVerified] = React.useState<boolean>(false)
  const [error, setError] = React.useState("")
  const [email, setEmail] = React.useState(username)
  const [codeSent, setCodeSent] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  useEffect(() => {
    Auth.confirmSignUp(username, code)
      .then(res => {
        DealerService.verifySalesPerson(props.data.site.siteMetadata.apiUrl, username.trim())
        .then(response => {
          if(response.Status === 200){
            navigate("/dealer-login")
          }
          setLoading(false)
        })
        setVerified(true)
        setError("")
      })
      .catch(err => {
        DealerService.verifySalesPerson(props.data.site.siteMetadata.apiUrl, username.trim())
        .then(response => {
          if(response.Status === 200){
            navigate("/dealer-login?resend-verification=true")
          }
          setLoading(false)
        })
        setVerified(false)
        setError(err.message)
      })
  }, [])

  return (
    <Layout version="blank">
      {!loading && 
      <AuthPageWrapper>
        <SignUpWrapper>
          <Grid>
            <Grid.Column textAlign="center" width={16}>
              {verified == undefined ? (
                <H6>
                  Loading
                  <LoadingIcon name="circle notch" size="large" loading />
                </H6>
              ) : verified == true ? (
                <div>
                  <div style={{ padding: "20px" }}>
                    <Icon name="check circle" color="green" size="massive" />
                  </div>
                  <H6>Success</H6>

                  <Caption
                    color={theme.brand.colors.darkGrey}
                    top={20}
                    bottom={20}
                  >
                    Your account has been verified.
                  </Caption>
                  <div>
                    <KpButton
                      id="confirm-login"
                      color={theme.brand.colors.green}
                      buttonType={ButtonTypes.Primary}
                      link="/login"
                    >
                      LOGIN
                    </KpButton>
                  </div>
                </div>
              ) : (
                <div>
                  <H6 color={theme.brand.colors.red}>
                    Email verification failed!
                  </H6>
                  <Caption top={20} bottom={20} color={theme.brand.colors.red}>
                    {error}
                  </Caption>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      Auth.resendSignUp(email).then(res => {
                        setCodeSent(true)
                      })
                    }}
                  >
                    <Form.Field style={{ textAlign: "left" }}>
                      <AuthFormLabel>Your Email Address</AuthFormLabel>
                      <Input
                        type="email"
                        name="email"
                        value={email}
                        id="email"
                        placeholder="Email address"
                        onChange={e => setEmail(e.target.value)}
                      />
                    </Form.Field>{" "}
                    <Form.Field
                      style={{ textAlign: "center", padding: "10px 0 10px 0" }}
                    >
                      <KpButton
                        id="resend-verification-submit"
                        color={theme.brand.colors.green}
                        buttonType={ButtonTypes.Primary}
                        type="submit"
                        disabled={codeSent}
                      >
                        Resend Verification Link
                      </KpButton>
                    </Form.Field>
                  </Form>
                  {codeSent && (
                    <div>
                      <Caption
                        top={20}
                        bottom={20}
                        color={theme.brand.colors.green}
                      >
                        Verification Email Sent to {email}.
                      </Caption>
                    </div>
                  )}
                </div>
              )}
            </Grid.Column>
          </Grid>
        </SignUpWrapper>
      </AuthPageWrapper>}
    </Layout>
  )
}

export default ConfirmSignupPage

export const query = graphql`{
    site {
      siteMetadata {
        apiUrl
        environment
        lastBuilt
      }
    }
}
`